import SearchRoute from 'crakn/routes/search';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

export default class AuthenticatedCraknClientsEditSfgsConfigEditLocationAccessRoute extends SearchRoute {
  @service store;

  queryParams = {
    page: {
      refreshModel: true
    },
    archived: {
      refreshModel: true
    }
  }

  async model() {
    const contractTemplate = this.modelFor('authenticated.crakn.clients.client.sfgs-config.edit');
    const locationTemplates = await this.store.query('v2/locationTemplate', {
      per_page: 1000,
      client_id: contractTemplate.get('client.id'),
      location_template_type: 'contract',
      contract_template_id: contractTemplate.id
    });

    return {
      locationTemplates,
      contractTemplate
    }
  }

  async setupController(controller, model) {
    super.setupController(...arguments);

    controller.setProperties({
      locationTemplates: await model.locationTemplates,
      contractTemplate: model.contractTemplate
    });

    await controller.setFilteredLocations();
  }

  resetController(controller, isExiting) {
    if (isExiting) {
      controller.setProperties({
        page: 1,
        meta: null
      });
    }
  }

  @action
  refreshModel() {
    this.refresh();
  }
}
