import Model, { attr, belongsTo } from '@ember-data/model';

export default class V2LocationTemplateModel extends Model {
  @attr('string') locationTemplateType;
  @attr('moment-datetime') archivedAt;
  @attr('string') locationId;


  @belongsTo('v2/contractTemplate', { async: true }) contractTemplate;
  @belongsTo('v2/location', { async: true }) location;
  @belongsTo('v2/soaTemplate') soaTemplate;
  @belongsTo('v2/client') client;
  @belongsTo('v2/user') archivedBy;
}
