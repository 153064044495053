import Model, { belongsTo, attr, hasMany } from '@ember-data/model';
import { validator, buildValidations } from 'ember-cp-validations';

const Validations = buildValidations({
  displayName: validator('presence', {
    presence: true,
    ignoreBlank: true,
    message: 'The display name should not be empty or consist only of spaces'
  })
});

export default class V2SoaTemplateModel extends Model.extend(Validations) {
  @attr('moment-datetime') archivedAt;

  @attr('string') displayName;
  @attr('string') templateName;

  @attr('boolean', { defaultValue: false }) miscSaleUseDefaultSoa;
  @attr('boolean', { defaultValue: false }) canViewLocation;
  @attr('boolean', { defaultValue: false }) showKaseInfo;
  @attr('boolean', { defaultValue: false }) showContractDate;
  @attr('boolean', { defaultValue: false }) showDeathDate;
  @attr('boolean', { defaultValue: false }) showProductNotes;
  @attr('boolean', { defaultValue: false }) hideDiscount;
  @attr('boolean', { defaultValue: false }) useCustomMargins;
  @attr('boolean', { defaultValue: false }) useCustomFont;
  @attr('boolean', { defaultValue: false }) showPaidInFull;

  @attr('string', { defaultValue: '0' }) marginTop;
  @attr('string', { defaultValue: '0' }) marginLeft;
  @attr('string', { defaultValue: '0' }) marginRight;
  @attr('string', { defaultValue: '0' }) marginBottom;
  @attr('number', { defaultValue: 10 }) customFontSize;

  @belongsTo('v2/client') client;
  @belongsTo('v2/user') createdBy;
  @belongsTo('v2/user') archivedBy;

  @hasMany('v2/locationTemplate') locationTemplates;
}
