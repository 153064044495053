import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import moment from 'moment';

export default class AuthenticatedKasesManageFinancialsInterestApplyRoute extends Route {

  @service router;
  @service session;

  queryParams = {
    selection_id: {
      refreshModel: true
    }
  }

  model() {
    const model = this.modelFor('authenticated.kases.manage.financials');
    return model.selections.filter(selection => selection.posted == true);
  }

  beforeModel() {
    const { can_view_kases, can_view_case_financial, can_view_interest_section } = this.session.currentClient;
    if (!(can_view_kases && can_view_case_financial && can_view_interest_section)) {
      this.router.transitionTo('authenticated.dashboard');
    }
  }

  setupController(controller, model, transition) {
    super.setupController(...arguments);

    let selection;
    const selectionId = transition?.from?.queryParams?.selection_id;
    if (selectionId) {
      selection = model.findBy('id', selectionId);
    } else {
      const oldestBalanceSelection = model.filter((selection) => selection.balance > 0);

      selection = isEmpty(oldestBalanceSelection) ? model.firstObject : oldestBalanceSelection[0];
    }

    if (selection) {
      controller.setSelection(selection.id);
    }
    controller.set('appliedBy', this.session?.currentUser?.id);
    controller.set('dateApplied', moment());
  }

  resetController(controller, isExiting) {
    if (isExiting) {
      controller.setProperties({
        gracePeriod:0,
        interestAmount: 0,
        note: null,
        selection: null,
        selectionId: null,
        showValidation: false,
      });
    }
  }
}
