import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';

export default class AuthenticatedKasesManageFinancialsInterestIndexRoute extends Route {

  @service router;
  @service session;

  queryParams = {
    selection_id: {
      refreshModel: true,
    },
  };

  model() {
    const model = this.modelFor('authenticated.kases.manage.financials');
    const postedSelections = model.selections.filter(selection => selection.posted == true);
    return postedSelections;
  }

  beforeModel() {
    const { can_view_kases, can_view_case_financial, can_view_interest_section } = this.session.currentClient;
    if (!(can_view_kases && can_view_case_financial && can_view_interest_section)) {
      this.router.transitionTo('authenticated.dashboard');
    }
  }

  setupController(controller, model, transition) {
    super.setupController(...arguments);
    
    let selection;
    const selection_id = transition?.to?.queryParams?.selection_id;

    if (selection_id) {
      selection = model.findBy('id', selection_id);
    } else {
      const oldestBalanceSelection = model.filter((selection) => selection.balance > 0);

      selection = isEmpty(oldestBalanceSelection) ? model.firstObject : oldestBalanceSelection[0];
    }

    if (selection) {
      controller.set('selection_id',selection.id);
      controller.set('selection',selection);
    }
  }

  resetController(controller, isExiting, transition) {
    if (isExiting) {
      if (transition?.targetName !== 'authenticated.kases.manage.financials.interest.apply') {
        controller.setProperties({
          selection_id: null,
          selection: null
        });
      }
    }
  }
}
